import { React, Component } from "react";
import LandingHeader from "../../components/landingHeader";
import {Services} from "../../components/Services";
import {CasoExito} from "../../components/Caso_exito";
import {Ubication} from "../../components/Ubication"
import Schedule from "../../components/schedule";
import MetaHelmet from "../../components/metaHelmet";

export default class Home extends Component {
    render() {
        return (
            <main>
                <MetaHelmet
                    titulo="Dra. Patricia Castro"
                    metaTitle="Dra Patricia Castro - Odontologia"
                    descripcion="Conozca a la Dra. Patricia Castro, destacada odontólogo de Guayaquil comprometida con su sonrisa y salud bucal."
                />
                <LandingHeader />
                <Services />
                <CasoExito />
                <Ubication />
                <Schedule currentColor="bg-athens" nextColor="bg-bluemine" />
            </main>
        )
    }
}