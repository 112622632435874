import { React } from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import RoundedSection from "../roundedSection";

export function CasoExito(props) {
    return (
        <RoundedSection currentColor="bg-athens" nextColor="bg-white">
            <div className="container w-full">
                <div className="flex flex-col items-center justify-center w-full py-10">
                    <p className="text-5xl font-bold text-bluemine text-center md:text-left md:px-4 md:w-full">Casos de éxito</p>
                    <p className="text-lg sm:text-4xl md:text-xl py-8 text-justify px-4  md:text-left md:px-4">La Dra. Patricia Castro ha logrado numerosos casos de éxito en su carrera odontológica, transformando sonrisas y mejorando la calidad de vida de sus pacientes con resultados sorprendentes y duraderos.
                    </p>
                </div>


                <div className="grid grid-cols-1 pb-8 lg:grid-cols-2 lg:gap-5 ">
                    <div className="flex flex-col items-center h-fit">
                        <ReactCompareSlider className="w-4/6  border-8 border-bluemine rounded-3xl"
                            itemOne={<ReactCompareSliderImage src="./despues1.png" alt="Image one" />}
                            itemTwo={<ReactCompareSliderImage src="./antes1.png" alt="Image two" />}
                        />
                        <p className="font-semibold py-4 text-2xl sm:text-3xl md:text-2xl">Coronas de Zirconium</p>
                    </div>
                    <div className="flex flex-col items-center h-fit">
                        <ReactCompareSlider className="w-4/6 border-8 border-bluemine rounded-3xl"
                            itemOne={<ReactCompareSliderImage src="./despues2.png" alt="Image one" />}
                            itemTwo={<ReactCompareSliderImage src="./antes2.png" alt="Image two" />}
                        />
                        <p className="font-semibold py-4 text-2xl sm:text-3xl md:text-2xl">Prótesis</p>
                    </div>
                    <div className="flex flex-col items-center h-fit lg:col-span-2">
                        <ReactCompareSlider className="w-4/6 lg:w-4/12 border-8 border-bluemine rounded-3xl"
                            itemOne={<ReactCompareSliderImage src="./despues3.png" alt="Image one" />}
                            itemTwo={<ReactCompareSliderImage src="./antes3.png" alt="Image two" />}
                        />
                        <p className="font-semibold py-4 text-2xl sm:text-3xl md:text-2xl">Restauración con Resina</p>
                    </div>
                    

                    {/* <div className="flex flex-row justify-center items-center w-[75%] space-x-16 py-8">
                        <div className="w-[100%] h-[400px] flex flex-col items-center">
                            <ReactCompareSlider className="w-[100%] h-[100%]  border-8 border-bluemine"
                                itemOne={<ReactCompareSliderImage src="./dentist-profile.png" alt="Image one" />}
                                itemTwo={<ReactCompareSliderImage src="./drpc-logo.svg" alt="Image two" />}
                            />
                            <p className="text-lg font-bold pt-8">Lorem Ipsum</p>
                        </div>
                        <div className="w-[100%] h-[400px] flex flex-col items-center">
                            <ReactCompareSlider className="w-[100%] h-[100%] border-8 border-bluemine"
                                itemOne={<ReactCompareSliderImage src="./dentist-profile.png" alt="Image one" />}
                                itemTwo={<ReactCompareSliderImage src="./drpc-logo.svg" alt="Image two" />}
                            />
                            <p className="text-lg font-bold pt-8">Lorem Ipsum</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </RoundedSection>
    )
}