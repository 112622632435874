import { React, Component } from "react";

export default class Footer extends Component {
    render() {
        return (
            <footer className="bg-bluemine flex justify-center">
                <div className="container flex flex-col items-center px-10 pt-10 pb-0">
                    <div className="flex flex-col lg:flex-row">
                        <div className="flex flex-row justify-center py-10 border-b-2  lg:border-b-0 lg:border-r-2  border-white w-full lg:w-1/2">
                            <img className="pr-2" src="muela.svg" alt="muela" />
                            <div className="flex flex-col">
                                <p className="text-3xl font-bold text-white">Dra. Patricia Castro, Odont.</p>
                                <p className="font-bold text-xl text-white">E-mail: <a className="hover:underline" href="mailto:patycastro3@hotmail.com">patycastro3@hotmail.com</a></p>
                            </div>
                        </div>
                        <div className="px-4 pt-10 lg:p-5 lg:pl-12 w-full lg:w-1/2">
                            <p className="text-white text-lg font-bold">Telf.: <span className="font-normal">2283178</span></p>
                            <p className="text-white text-lg font-bold">Cel.: <span className="font-normal">0985895707</span></p>
                            <p className="text-white text-lg font-bold">Ubicación: <span className="font-normal">Clínica Kennedy, Edificio Epsilon 2do piso, Oficina #2, Av. San Jorge 424</span></p>
                            {/* <p className="text-white text-lg font-bold">Oficina #2, Av. San Jorge 424</p> */}
                        </div>

                    </div>
                    <div className="flex flex-col items-center p-5">
                        <a className="w-[40%]" href="https://navvi.studio/" rel="noopener noreferrer" target="_blank">
                            <img src="./PBN.png" alt="powered by navvi"></img>
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
}