import { React, Component } from "react";
import RoundedSection from "../../components/roundedSection";
import Schedule from "../../components/schedule/index";
import MetaHelmet from "../../components/metaHelmet";

export default class Portafolio extends Component {
    render() {
        return (
            <>
                <MetaHelmet
                    titulo="Servicios - Dra. Patricia Castro"
                    metaTitle="Dra Patricia Castro - Servicios"
                    descripcion="La Dra. Patricia Castro ofrece blanqueamiento dental, prótesis y carillas de resina, brindando soluciones personalizadas para su sonrisa"
                />
                <RoundedSection currentColor="bg-athens" nextColor="bg-white">
                    <div className="container w-full py-10">
                        <div className="flex flex-col w-full py-6 justify-center items-center">
                            <p className=" text-bluemine  font-bold text-5xl justify-center items-center">Servicios</p>
                            <div className="text-black text-justify text-2xl w-[80%] py-8 justify-center">
                                La Dra. Patricia Castro ofrece cuidados dentales especializados y dedicados a la preservación de la salud bucal de sus pacientes.
                            </div>
                        </div>
                        <div className="grid px-14 grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-0 lg:grid-cols-4 lg:gap-5">
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./blanqueamiento.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Blanqueamiento dental</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./corona-modern.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Coronas: Emax/Zirconium</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./exodoncia.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Exodoncia</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./limpieza.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Limpieza dental</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./protesis.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Prótesis</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./restauracionresin.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Restauraciones de Resina</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./rootcanal.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Tratamiento de conducto</p>
                            </div>
                            <div className="flex flex-col items-center py-4">
                                <img className="w-[70%] sm:w-[60%]  border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./carillas.png" alt="logo"></img>
                                <p className="pt-4 justify-center text-xl font-bold">Carillas de resina</p>
                            </div>
                        </div>
                    </div>
                </RoundedSection>
                <Schedule nextColor="bg-bluemine" currentColor="bg-white" />
            </>

        )
    }
}