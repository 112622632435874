import { React, Component } from "react";
import RoundedSection from "../../components/roundedSection";
import ImageFrame from "../../components/imageFrame";
import "./contact.css";
import MetaHelmet from "../../components/metaHelmet";

export default class Contacto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    handleChange = (e) => {
        if (e.target.id === "select-mobile") {
            document.getElementById("subject-mobile").value = e.target.value;
        } else {
            document.getElementById("subject-desktop").value = e.target.value;
        }
    };

    setCurrentDate = () => {
        var d = new Date();
        d.setUTCHours(12, 0, 0, 0);
        return (d.toISOString().substr(0, 16));
    }

    componentDidMount() {
        document.getElementById("date-mobile").value = this.setCurrentDate();
    }

    handleModalDismiss = () => {
        this.setState({
            modal: false
        });
    }

    handleModalEnable = (e) => {
        e.preventDefault();
        const isMobile = navigator.userAgentData.mobile;
        const form = isMobile ? "mobile-form" : "desktop-form";
        if (!document.getElementById(form).checkValidity()) {
            document.getElementById(form).reportValidity();
        } else {
            this.setState({
                modal: true
            });
            document.getElementById(form).submit()
        }
    }


    render() {
        return (
            <>
                <MetaHelmet
                    titulo="Contacto - Dra. Patricia Castro"
                    metaTitle="Dra Patricia Castro - Contacto"
                    descripcion="¿Busca un dentista en Guayaquil especializado en limpieza dental? Contacte a la Dra. Patricia Castro para consultas y citas."
                />
                <RoundedSection currentColor="bg-athens" nextColor="bg-bluemine">
                    <div className="container w-full py-8">
                        <div className="flex flex-col w-full py-10 justify-center items-center">
                            <p className=" text-bluemine  font-bold text-5xl justify-center items-center">Contacto</p>
                            <div className="text-black text-justify text-2xl w-[80%] py-8 justify-center">
                                La Dra. Patricia Castro brinda atención dental con citas previas, asegurando la eficacia y la satisfacción del paciente, con un enfoque primordial en su salud y bienestar. En caso de emergencia, está pronta a dar sus servicios para aliviar al paciente.
                            </div>
                        </div>
                        <div className="flex flex-row justify-center items-start">
                            <ImageFrame src="./dra-patient.png" class="lg:flex flex-col w-[30%] 2xl:w-[30%] items-center pb-0 hidden " />
                            <div className="flex flex-row md:space-x-1 lg:space-x-8 xl:space-x-4 md:w-1/2 h-fit justify-center md:items-start items-center">
                                <div className="lg:flex flex-col items-baseline space-y-10 hidden">
                                    <p className="font-bold text-xl">Correo:</p>
                                    <p className="font-bold text-xl ">Nombre:</p>
                                    <p className="font-bold text-xl ">Celular:</p>
                                    <p className="font-bold text-xl ">Fecha y Hora:</p>
                                    <p className="font-bold text-xl ">Servicio:</p>
                                    <p className="font-bold text-xl ">Mensaje (opcional):</p>
                                </div>
                                {/*formulario de movil*/}
                                <div className="flex flex-col items-center justify-center space-y-8 lg:w-1/2 pb-8 px-4 lg:px-0 lg:hidden">
                                    <ImageFrame src="./dra-patient.png" class="flex flex-col w-[85%] justify-center items-center pb-8" />
                                    <form id="mobile-form" action="https://formsubmit.co/patycastro3@hotmail.com" method="POST">
                                        <input className="rounded-3xl w-full px-4 mb-4 pt-1 text-xl border-[1px] border-bluemine" type="text" name="Correo" placeholder="Correo" required></input>
                                        <input className="rounded-3xl w-full px-4 my-4 pt-1 text-xl border-[1px] border-bluemine" type="text" name="Nombre" placeholder="Nombre" required></input>
                                        <input className="rounded-3xl w-full px-4 my-4 pt-1 text-xl border-[1px] border-bluemine" type="text" name="Celular" placeholder="Teléfono" required></input>
                                        <input id="date-mobile" className="rounded-3xl w-full px-4 my-4 pt-1 text-xl border-[1px] border-bluemine bg-white appearance-none" type="datetime-local" name="Fecha" required></input>
                                        <div className="select-wrapper w-full">
                                            <select id="select-mobile" onChange={(e) => this.handleChange(e)} className="select-wrapper rounded-3xl w-full px-4 py-1 text-xl my-5 border-[1px] bg-white border-bluemine appearance-none" type="text">
                                                <option value="default" defaultValue disabled hidden>Escoja un servicio</option>
                                                <option value="Consulta">Consulta</option>
                                                <option value="Limpieza">Limpieza</option>
                                                <option value="Prótesis">Prótesis</option>
                                                <option value="Blanqueamiento">Blanqueamiento</option>
                                                <option value="Corona">Corona</option>
                                            </select>
                                        </div>
                                        <textarea name="Mensaje" id="mensaje-paciente" className="h-fit rounded-3xl w-full my-4 px-4 py-3 justify-center text-xl border-[1px] border-bluemine resize-none active:border-bluemine" cols="30" rows="8" placeholder="Describa el motivo de su cita"></textarea>
                                        <input type="hidden" name="_template" value="box"></input>
                                        <input type="hidden" id="subject-mobile" name="_subject" value="Un Paciente se ha Contactado!"></input>
                                        <input type="hidden" name="_next" value="https://drapattycastro.com/"></input>

                                        <button type="submit" className="bg-calypso text-white p-4 rounded-3xl w-full hover:bg-calypso-500">Enviar</button>
                                    </form>
                                </div>

                                <div className=" hidden lg:flex flex-col items-center space-y-8 md:w-1/2 pb-8 px-4 md:px-0">
                                    <form id="desktop-form" action="https://formsubmit.co/patycastro3@hotmail.com" method="POST">
                                        <input className="rounded-3xl w-full px-4 pt-1 mb-4 text-xl border-[1px] border-bluemine" type="text" name="Correo" placeholder="example@gmail.com" required></input>
                                        <input className="rounded-3xl w-full px-4 pt-1 my-4 text-xl border-[1px] border-bluemine" type="text" name="Nombre" placeholder="Su nombre y apellido" required></input>
                                        <input className="rounded-3xl w-full px-4 pt-1 my-4 text-xl border-[1px] border-bluemine" type="text" name="Celular" placeholder="0998172635" required></input>
                                        <input className="rounded-3xl w-full px-4 pt-1 my-4 text-xl border-[1px] border-bluemine appearance-none" defaultValue={() => this.setCurrentDate()} type="datetime-local" name="Fecha" required></input>
                                        <div className="select-wrapper w-full">
                                            <select id="select-desktop" onChange={(e) => this.handleChange(e)} className="rounded-3xl w-full px-4 py-1 my-5 text-xl border-[1px] border-bluemine appearance-none" type="text">
                                                <option value="default" defaultValue disabled hidden>Escoja un servicio</option>
                                                <option value="Consulta">Consulta</option>
                                                <option value="Limpieza">Limpieza</option>
                                                <option value="Prótesis">Prótesis</option>
                                                <option value="Blanqueamiento">Blanqueamiento</option>
                                                <option value="Corona">Corona</option>
                                            </select>
                                        </div>
                                        <textarea name="Mensaje" id="mensaje-paciente" className="rounded-3xl w-full px-4 py-3 my-4 justify-center text-xl border-[1px] border-bluemine resize-none active:border-bluemine" cols="30" rows="8" placeholder="Describa el motivo de su cita"></textarea>
                                        <input type="hidden" name="_template" value="box"></input>
                                        <input type="hidden" id="subject-desktop" name="_subject" value="Consulta"></input>
                                        <input type="hidden" name="_next" value="https://drapattycastro.com/"></input>
                                        <button type="submit" className="bg-calypso text-white p-4 rounded-3xl w-full hover:bg-calypso-500 enviar" onClick={this.handleModalEnable}>Enviar</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.modal &&
                        <div className="fixed h-[100vh] w-[100vw] backdrop-blur bg-black/60 z-30 top-0 left-0 flex justify-center items-center transition-all" onClick={this.handleModalDismiss}>
                            <div className="flex flex-col items-center bg-athens text-beige border border-beige/50 rounded-3xl p-5 text-center max-w-md gap-4" onClick={() => { }}>
                                <div className='w-[70%]'>
                                    <h1 className="text-3xl text-center font-bold text-bluemine">¡Gracias por separar tu cita!</h1>
                                </div>
                                <div className="text-xl text-justify px-10">
                                    <h2 className="text-calypso font-semibold" dangerouslySetInnerHTML={{ __html: "La Dra. Castro se comunicará con usted para confirmar la fecha y hora. En caso de que no haya disponibilidad, le notificará de inmediato. Para emergencias, llame al siguiente número: 0985895707." }} />
                                </div>
                                <div>
                                    <button
                                        onClick={this.handleModalDismiss}
                                        className="bg-calypso border-2 text-white text-xl py-2 px-8 rounded-full hover:bg-calypso-500"
                                    >
                                        Entiendo
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </RoundedSection>
            </>
        )
    }
}