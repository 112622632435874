import { React} from "react";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export function Navbarmobile({toogle}) {
    return (
        <RoundedSection nextColor="bg-athens" currentColor="bg-white flex flex-col justify-center items-center space-y-5 p-8">
            <Link to={"/sobre"}>
                <p className="text-xl text-black font-bold hover:text-calypso text-center" onClick={toogle}>Acerca de</p>
            </Link>
            <Link to={"/servicios"}>
                <p className="text-xl text-black font-bold hover:text-calypso" onClick={toogle}>Servicios</p>
            </Link>
            <Link to={"/contacto"}>
                <p className="text-xl text-black font-bold hover:text-calypso" onClick={toogle} >Contacto</p>
            </Link>
        </RoundedSection>
    )
}