import {Component } from "react";

export default class RoundedSection extends Component {
    render (){
        return(
            <section className={`${this.props.nextColor}`}>
                <div className={`flex justify-center rounded-b-[2rem] w-full ${this.props.currentColor}`}>
                    {this.props.children}
                </div>
            </section>
        )
    }
}