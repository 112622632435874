import { React, Component } from "react";
import Schedule from "../../components/schedule";
import RoundedSection from "../../components/roundedSection";
import ImageFrame from "../../components/imageFrame";
import MetaHelmet from "../../components/metaHelmet";

export default class About extends Component {
    render() {
        return (
            <>
                <MetaHelmet
                    titulo="Acerca de - Dra. Patricia Castro"
                    metaTitle="Dra Patricia Castro - Acerca de"
                    descripcion="Conozca a la Dra. Patricia Castro Gómez, destacada odontóloga de Guayaquil formada en la Universidad de Campinas"
                />
                <RoundedSection currentColor="bg-athens" nextColor="bg-white">
                    <div className="container flex flex-col-reverse items-center lg:flex-row justify-center md:space-x-8 p-12">
                        <div className="flex flex-col w-full lg:w-[35%]">
                            <p className="hidden lg:block text-bluemine font-bold text-5xl text-right">Acerca de</p>
                            <p className="hidden lg:block text-calypso font-bold lg:text-4xl xl:text-5xl 2xl:text-6xl text-left py-3">La Dra. Patricia Castro Gomez</p>
                            <p className="text-justify pb-3 text-lg">Nacida en la ciudad de Guayaquil, la Dra. Castro demostró desde
                                temprana edad un gusto por el cuidado de los demás. Su pasión la llevó a estudiar Odontología en la Universidad Estatal de Guayaquil.
                            </p>
                            <p className="text-justify pb-3 text-lg">Para su especialidad, decidió estudiar en la Facultad de Odontología de Piracicaba de la Universidad de Campinas, São Paulo en donde obtuvo el título de Especialista en Endodoncia.</p>
                            <p className="text-justify pb-3 text-lg">Definida como una persona con un carácter amable y respetuoso, gusta de la jardinería, lectura, música, y de servir a los demás, siempre ha buscado actualizarse para brindar a sus pacientes la mejor atención.</p>
                            <p className="text-justify text-lg">De religión católica, pertenece al Movimiento de Schoenstatt en Ecuador.</p>
                        </div>
                        <ImageFrame class="py-4 lg:py-0 sm:w-[60%] lg:w-[60%] xl:w-[42%] 2xl:w-[34%]" src="drpc-about.png" />
                        <p className="flex lg:hidden text-calypso font-bold text-3xl sm:text-4xl text-center py-3">La Dra. Patricia Castro Gomez</p>
                        <p className="flex lg:hidden text-bluemine font-bold text-5xl justify-center items-center sm:text-3xl text-center">Acerca de</p>
                    </div>
                </RoundedSection>
                <Schedule currentColor="bg-white" nextColor="bg-bluemine" />
            </>

        )
    }
}