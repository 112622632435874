import { React, Component } from "react";
/*import RoundedSection from "../roundedSection";*/
import { Link } from "react-router-dom";

export default class Navbar extends Component {
    componentDidUpdate(){
        window.scrollTo(0,0);
    }
    render() {
        return (
                <div className="p-8 flex flex-row w-full xl:justify-center items-center justify-between xl:space-x-48">
                    <Link to={"/"}>
                        <div className="flex flex-row items-center w-full">
                            <img className="no-drag pr-2 " src="./drpc-logo.svg" alt="logo"></img>
                            <p className="font-bold text-xl sm:text-3xl text-bluemine">Dra. Patricia Castro</p>
                        </div>
                    </Link>
                    <div className="flex-row space-x-36 xl:space-x-44 hidden lg:flex ">
                        <Link to={"/sobre"}>
                            <p className="text-xl text-black font-bold hover:text-calypso text-center">Acerca de</p>
                        </Link>
                        <Link to={"/servicios"}>
                            <p className="text-xl text-black font-bold hover:text-calypso">Servicios</p>
                        </Link>
                        <Link to={"/contacto"}>
                            <p className="text-xl text-black font-bold hover:text-calypso">Contacto</p>
                        </Link>
                    </div>
                    <img className="no-drag lg:hidden w-[8%] sm:w-[4%]" src="./btn-burger.svg" alt="logo" onClick={this.props.abrir}></img>
                </div>
          
        )
    }
}