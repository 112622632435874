import { React } from "react";
import ImageFrame from "../imageFrame";
import RoundedSection from "../roundedSection";
export function Ubication(props) {
    return (
        <RoundedSection currentColor="bg-white" nextColor="bg-athens">
            <div className="container w-full">
                <p className="text-5xl font-bold text-bluemine py-10 pl-0 text-center md:text-left md:px-4">Ubicación</p>
                <div className="flex flex-row justify-center px-8 pb-12 md:space-x-12">
                    <div className="flex flex-col w-full justify-center space-y-4 md:w-2/5">
                        <div className="flex flex-col h-1/2 bg-calypso text-white text-3xl justify-center rounded-2xl px-6 py-8">
                            <p className="px-4 pt-4 font-bold">Clínica Kennedy</p>
                            <p className="px-4 pb-4 font-bold">Edif. Epsilon 2do. piso Of. #2, Av. San Jorge 424</p>
                        </div>
                        <iframe className="h-full border border-bluemine rounded-2xl" title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.947751162593!2d-79.9009137241671!3d-2.1735574372413637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6def24841b61%3A0x1d973ecde07186a4!2sEdificio%20EPSILON%20Hospital%20Cl%C3%ADnica%20Kennedy!5e0!3m2!1ses!2sec!4v1709847184643!5m2!1ses!2sec"></iframe>
                    </div>
                    <ImageFrame src="./edificio-epsilon.png" class=" hidden md:flex lg:w-[40%] flex-col w-full justify-center items-center" />
                </div>
            </div>
        </RoundedSection>
    )
}
