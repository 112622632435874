import './App.css';
import Navbar from './components/navbar';
import Footer from './components/footer';
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
  Outlet
} from "react-router-dom";
import { useEffect } from 'react';
import Home from './routes/home';
import About from './routes/about';
import Portafolio from './routes/services';
import Contacto from './routes/contact';
import { useState } from 'react';
import RoundedSection from './components/roundedSection';
import { Navbarmobile } from './components/navbar_mobile';

function NavigatableSite() {
  let navigate = useNavigate();
  let location = useLocation();
  const [isopen, setIsopen] = useState(false);
  const toogle = () => {
    setIsopen(!isopen);
  };
  return (
    <div className="main-container">
      <div className="content-container">
        {isopen && <RoundedSection currentColor="bg-white" nextColor="bg-white">
          <Navbar navigate={navigate} location={location} abrir={toogle} />
        </RoundedSection>}
        {!isopen && <RoundedSection currentColor="bg-white" nextColor="bg-athens">
          <Navbar navigate={navigate} location={location} abrir={toogle} />
        </RoundedSection>}
        {isopen && <Navbarmobile toogle={toogle}/>}
        <div className="transition-all">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
}

function Redirect() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  });
  return null;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <NavigatableSite />,
    errorElement: <Redirect />,
    children: [
      {
        index: true,
        path: "/",
        element: <Home />,
      },
     {
        path: "/sobre",
        element: <About />
      },
      { 
        path: "/servicios",
        element: <Portafolio />
      },
      {
        path: "/contacto",
        element: <Contacto />
      },
    ],
  }
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
