import { Component } from "react"
import { Helmet } from "react-helmet"

export default class MetaHelmet extends Component {

    render() {
        return (
            <Helmet>
                <title>{this.props.titulo}</title>
                <meta name="title" content={this.props.titulo} />
                <meta property="og:title" content={this.props.metaTitle} />
                <meta name="description" content={this.props.descripcion} />
                <meta property="og:description" content={this.props.descripcion} />
            </Helmet>
        );
    }

}