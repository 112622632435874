import { React } from "react";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export function Services(props) {
    return (
        <RoundedSection currentColor="bg-white" nextColor="bg-athens">
            <div className="container w-full">
                <div className="flex flex-col items-center justify-center w-full py-10">
                    <p className=" text-bluemine font-bold text-5xl md:px-4 md:w-full md:text-left ">Servicios</p>
                    <div className="text-black text-lg text-justify px-4 pt-8 sm:text-4xl md:px-4 md:text-left md:text-xl md:w-full  ">
                    La Dra. Patricia Castro ofrece una amplia gama de servicios dentales, incluyendo atención preventiva, tratamientos restaurativos y procedimientos estéticos, garantizando la salud y el bienestar bucal de sus pacientes.
                    </div>
                </div>
                <div className=" px-16 sm:px-36 lg:px-20 items-start grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 lg:gap-24 ">
                    <div className=" flex flex-col justify-center items-center">
                        <img className="border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./corona-modern.png" alt="logo"></img>
                        <p className="py-4 text-center text-2xl sm:text-3xl md:text-2xl font-semibold">Coronas en Emax/Zirconium</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img className="border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./endodoncia.png" alt="logo"></img>
                        <p className="py-4 text-center text-2xl sm:text-3xl md:text-2xl font-semibold">Endodoncia</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img className="border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./blanqueamiento.png" alt="logo"></img>
                        <p className="py-4 text-center text-2xl sm:text-3xl md:text-2xl font-semibold">Blanqueamiento dental</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img className="border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./protesis.png" alt="logo"></img>
                        <p className="py-4 text-center text-2xl sm:text-3xl md:text-2xl font-semibold">Prótesis</p>
                    </div>
                </div>
                <div className="flex justify-center py-14">
                    <Link to="/servicios">
                        <button className="bg-calypso text-white md:text-xl font-bold p-5 px-8 rounded-3xl">Ver todos los servicios</button>
                    </Link>
                </div>
            </div>
        </RoundedSection>
    )
}