import { React, Component } from "react";
import ImageFrame from "../imageFrame";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export default class LandingHeader extends Component {
    render() {
        return (
            <RoundedSection currentColor="bg-athens">
                <div className="container p-5 lg:p-14">
                    <div className="flex flex-row justify-center lg:space-x-10 ">
                        <ImageFrame src="dentist-profile.png" class="hidden lg:block" />
                        <div className="flex flex-col w-full md:w-[80%] lg:w-[55%] xl:w-[47%] items-center space-y-8">
                            <p className="font-bold text-4xl text-center lg:text-5xl lg:text-left xl:text-7xl 2xl:text-8xl w-full text-bluemine pt-2">Dra. Patricia Castro Gomez</p>
                            <ImageFrame src="dentist-profile.png" class="lg:hidden" />
                            <div className="flex flex-col px-3 py-8 my-3 bg-bluemine rounded-[40px] w-full">
                                <div className="flex flex-col text-center pb-4">
                                    <p className="font-bold text-xl sm:text-3xl lg:text-4xl  text-white">Odontóloga con Especialidad</p>
                                    <p className="font-bold text-xl sm:text-3xl lg:text-4xl  text-white">en Endodoncia</p>
                                </div>
                                <div className="flex flex-row justify-center space-x-8">
                                    <div className="relative flex justify-center items-center">
                                        <img className="w-full " src="muela-vector.png" alt="logo" />
                                        <p className="text-white absolute text-sm sm:text-2xl lg:text-xl xl:text-3xl">Limpieza</p>
                                    </div>
                                    <div className="relative flex justify-center items-center">
                                        <img className="w-full " src="muela-vector.png" alt="logo" />
                                        <p className="text-white absolute text-sm sm:text-2xl lg:text-xl xl:text-3xl">Cirugía</p>
                                    </div>
                                    <div className="relative flex justify-center items-center">
                                        <img className="w-full " src="muela-vector.png" alt="logo" />
                                        <p className="text-white absolute text-sm sm:text-2xl lg:text-xl xl:text-3xl ">Prótesis</p>
                                    </div>
                                </div>
                            </div>
                            <Link to="/contacto">
                                <div className="flex flex-row w-full">
                                    <p className="text-white text-xl bg-calypso rounded-3xl p-5 px-8 ">Agenda tu cita aquí</p>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </RoundedSection>
        )
    }
}